$white: #ffffff;
$black: #000000;
$primary: #626ed4;
$secondary: #3c445a;
$success: #02a499;
$info: #38a4f8;
$warning: #f8b425;
$danger: #ec4561;
$dark: #2a3142;
$muted: #9ca8b3;
$separator: #444a58;
$gray-badge: #7d7d7d;
$gray: #e3e5e9;
$muted: #9ca8b3;
$background: #efefef;
$nav-bar: #333547;
$text: #5b626b;

// Reunion Types
$meeting_seguimiento: #a262d4;
$meeting_match: #f87125;
$meeting_sales: #ef5da8;

// Shadows copied from Bootstrap 4 source
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075) !default;

$font-family: 'Sarabun', sans-serif;

$primaryLightColor: rgba(38, 143, 255, 0.5) !default;
$primaryColor: $primary !default;
$primaryDarkColor: #0069d9 !default;
$primaryDarkerColor: #0062cc !default;
$primaryTextColor: #ffffff !default;
$primaryAccentBg: #cce5ff !default;

$highlightBg: $primaryColor !default;
$highlightTextColor: $primaryTextColor !default;
$highlightFocusBg: darken($primaryColor, 8%) !default;

@import '../_variables_light';

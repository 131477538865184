.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

.d-flex {
    display: flex !important;
}

.flex-column {
    flex-direction: column;
}

.w-100 {
    width: 100% !important;
}

.ml-auto {
    margin-left: auto !important;
}

.text-upper {
    text-transform: uppercase;
}

.mr {
    margin-right: 1rem;
}

.mb {
    margin-right: 1rem;
}

.mb-lg {
    margin-bottom: 2rem;
}

.p-button-full-width {
    .p-button {
        width: 100%;
    }
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-with-label-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 5px;
}

@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');
@import 'fontawesome/scss/fontawesome.scss';

@import 'variables';
@import './atoms/utilities';
@import './primeng-sass-theme-17.7.0/themes/bootstrap4/bootstrap4-light/blue/theme';
@import '../../../node_modules/primeng/resources/primeng.css';
@import './atoms/text';

@layer global-tweaks {
    * {
        font-family: $font-family;
    }

    body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        overflow-x: hidden;
    }

    main {
        background-color: $background;
    }

    .p-component-overlay {
        // Glass blur effect
        backdrop-filter: blur(5px);
        background-color: rgba(255, 255, 255, 0.3);
    }

    .p-chip {
        margin: 4px;
    }

    .component-container {
        background: $white;
        padding: 1rem;
        border-radius: 15px;
        box-shadow: $box-shadow;
    }

    a.p-button {
        text-decoration: none;
    }

    .p-breadcrumb {
        margin: 10px 0;
        background: $gray;
    }
}

body {
    color: $text !important;
}

.text-muted {
    color: $muted;
    font-size: 0.875rem;
    font-weight: normal;
    margin: 0;
}

.text-error {
    color: $danger;
    font-size: 0.875rem;
    font-weight: normal;
    margin: 0;
}

.table-anchor {
    color: $text;
    text-decoration: none;

    &:hover {
        color: $primary;
    }
}
